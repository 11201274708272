<template>
  <div class="smart-farm">
    <!-- 导航栏 -->
    <Nav :isHome="false" pageName="join"></Nav>
    <!-- <CommonNav pageName="智慧农业"></CommonNav> -->
    <section>
      <img class="smart-farm-bg" src="../../assets/images/join/bg.png" alt="">
    </section>
    <section class="smart-farm-container">
      <div class="container-first">
        <div class="container-first-list" @click="getJoinPage('it')">
          <img class="container-first-list-img" src="../../assets/images/join/itImg.png" alt="">
          <h4>研发部</h4>
          <img class="container-first-list-more" src="../../assets/images/join/more.png" alt="">
        </div>
        <div class="container-first-list" @click="getJoinPage('hardware')">
          <img class="container-first-list-img" src="../../assets/images/join/hardwareImg.png" alt="">
          <h4>硬件&AI部门</h4>
          <img class="container-first-list-more" src="../../assets/images/join/more.png" alt="">
        </div>
        <div class="container-first-list" @click="getJoinPage('mart')">
          <img class="container-first-list-img" src="../../assets/images/join/martImg.png" alt="">
          <h4>市场部</h4>
          <img class="container-first-list-more" src="../../assets/images/join/more.png" alt="">
        </div>
        <div class="container-first-list" @click="getJoinPage('code')">
          <img class="container-first-list-img" src="../../assets/images/join/codeImg.png" alt="">
          <h4>教育部</h4>
          <img class="container-first-list-more" src="../../assets/images/join/more.png" alt="">
        </div>
      </div>
      <div class="container-second">
        <div class="container-second-list" @click="getJoinPage('sxj')">
          <img class="container-second-list-img" src="../../assets/images/join/sxjImg.png" alt="">
          <h4>社享家</h4>
          <img class="container-first-list-more" src="../../assets/images/join/more.png" alt="">
        </div>
        <div class="container-second-list">
          <img class="container-second-list-img" src="../../assets/images/join/moneyImg.png" alt="">
          <h4>财务部</h4>
          <img class="container-first-list-more" src="../../assets/images/join/more.png" alt="">
        </div>
        <div class="container-second-list">
          <img class="container-second-list-img" src="../../assets/images/join/hrImg.png" alt="">
          <h4>人事部</h4>
          <img class="container-first-list-more" src="../../assets/images/join/more.png" alt="">
        </div>
        <div class="container-second-list">
          <img class="container-second-list-img" src="../../assets/images/join/officeImg.png" alt="">
          <h4>行政部</h4>
          <img class="container-first-list-more" src="../../assets/images/join/more.png" alt="">
        </div>
      </div>
    </section>
    <!-- 底部 -->
    <Footer></Footer>
  </div>
</template>

<script>
import Nav from '@/components/nav.vue'
// import CommonNav from '@/components/commonNav.vue'
import Footer from '@/components/footer.vue'

export default {
  data() {
    return {

    }
  },
  components: {
    Nav,
    // CommonNav,
    Footer
  },
  methods:{
    getJoinPage(type){
      this.$router.push({
        path:'/detail',
        query:{
          type
        }
      })
    }
  },
  beforeDestroy() {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
.smart-farm {
  width: 100vw;
  padding-top: 80px;
}

.smart-farm-bg {
  width: 100%;
  height: 100%;
}

.smart-farm-container {
  width: 70.7%;
  margin: 106px auto 155px;
  font-size: 22px;
  color: #808080;
  text-align: left;

  .container-first {
    display: flex;
    justify-content: space-between;
    .container-first-list {
      width: 16.9%;
      flex: 1;
      max-width: 325px;
      height: 487px;
      padding-left: 29px;
      background: linear-gradient(38deg, #fdfbfb 0%, #ebedee 100%);
      box-shadow: 0px 0px 3.88px 0px rgba(29, 29, 29, 0.15);
      margin-right: 0.9%;
      color: #000000;
      font-size: 26px;
      line-height: 1;
      cursor: pointer;
      .container-first-list-img{
        margin-top: 283px;
        margin-bottom: 35px;
      }
      h4{
        font-weight: 500;
        margin-bottom: 18px;
      }
    }
  }
  .container-first .container-first-list:nth-child(2n-1){
    margin-top: 64px;
  }
  .container-second{
    display: flex;
    justify-content: space-between;
    .container-second-list {
      width: 16.9%;
      flex: 1;
      max-width: 325px;
      height: 487px;
      padding-left: 29px;
      background: linear-gradient(38deg, #fdfbfb 0%, #ebedee 100%);
      box-shadow: 0px 0px 3.88px 0px rgba(29, 29, 29, 0.15);
      margin-right: 0.9%;
      color: #000000;
      font-size: 26px;
      line-height: 1;
      .container-second-list-img{
        margin-top: 283px;
        margin-bottom: 35px;
      }
      .container-first-list-more{
        width: 84px;
        height: 18px;
      }
      h4{
        font-weight: 500;
        margin-bottom: 18px;
      }
    }
  }
  .container-second .container-second-list:nth-child(2n-1){
    margin-top: 64px;
  }
  .container-second .container-second-list:nth-child(1){
    cursor: pointer;
  }
 
}
</style>